<!-- 修改密码 -->
<template>
  <div class="change-pass-page">
    <div class="form-wrapper">
      <van-form  ref="changePassForm">
        <van-field
          v-model="word"
          required
          placeholder="请输入‘确认注销’"
          label="是否确认"
        >
        </van-field>
        <p class="remark">
          注销账号将永久删除您的所有数据，且无法恢复，您将无法使用本APP提供的所有服务。本APP将不保留您的任何用户信息，确认这样做么，如果确定请在输入框中输入“确认注销”四个字
        </p>
        <div class="btn-box">
          <div class="btn">
            <van-button round block type="primary" @click="onSubmit"
              >提交</van-button
            >
          </div>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      word: "",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onSubmit(values) {
      if (this.word == "确认注销") {
        this.$toast("注销成功，系统将于1个工作日内删除您的所有用户数据，在此期间请不要尝试登录。");
      }else{
        this.$toast("请在输入框中输入‘确认注销’")
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.change-pass-page {
  width: 100%;
  height: 100%;
  background: #fafafa;
  position: relative;
  padding-bottom: 0.2rem;
  .form-wrapper {
    padding: 0.2rem;
    .remark {
      width: 100%;
      font-size: 0.2rem;
      font-weight: 400;
      color: #999999;
      background: #fff;
      padding: 0.2rem;
    }
    .btn-box {
      width: 90%;
      bottom: 0rem;
      margin: 1rem 0.16rem 0;
      .btn {
        width: 100%;
      }
    }
  }
}
</style>
